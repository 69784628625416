import { IMarketplaceAssetPayload } from '../../../models/cbo/CboMarketplaceModels';
import {
  IConversionPayload,
  IConfigurePayload,
  IEngagementConfigurationPayload,
  IEngagementConfigurationResponse,
  IEngagementListForAdminRequestPayload,
  IEngagementListForAdminResponse,
  IEngagementResponse,
  IKeyAssetsIconResponseListObject,
  IPredefinedJourneyResponse,
  IProduct,
  IValidatePursuitPayload,
  IJourneyCapabilitiesResponse,
  IEngagementClosureDatePayload,
  IStatusUpdateResponse,
} from '../../../models/cbo/EngagementModels';
import { API_ROUTE_CBO } from '../../ApiRoute';
import ApiService from '../../ApiService';
import ApiServiceBase from '../../ApiServiceBase';
import { ServerType, ServiceType } from '../../ServiceType.data';

export class EngagementService {
  private readonly cboService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.cbo);
  private readonly localstack: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.localStack,
  );

  public getEngagementList(
    requestPayload: IEngagementListForAdminRequestPayload,
  ): Promise<IEngagementResponse[]> | IEngagementResponse[] {
    const route: string[] = [
      `${requestPayload.isEngagement ? API_ROUTE_CBO.GET_ENGAGEMENTS : API_ROUTE_CBO.GET_PURSUITS}`,
    ];
    const { isEngagement, page, limit, ...payload } = requestPayload;
    return this.cboService.post({ route }, payload);
  }

  public getEngagementListForAdmin(
    requestPayload: IEngagementListForAdminRequestPayload,
  ): Promise<IEngagementListForAdminResponse> | IEngagementListForAdminResponse {
    const route: string[] = [
      `${requestPayload.isEngagement ? API_ROUTE_CBO.GET_ENGAGEMENTS : API_ROUTE_CBO.GET_PURSUITS}`,
    ];
    const { isEngagement, ...payload } = requestPayload;
    return this.cboService.post({ route }, payload);
  }

  public getPredefinedJourneyList():
    | Promise<IPredefinedJourneyResponse[]>
    | IPredefinedJourneyResponse[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_CE_PREDEFINED_JOURNEY_LIST}`];
    return this.cboService.get({ route: route });
  }

  public getKeyAssetList(data: IMarketplaceAssetPayload): Promise<IProduct[]> | IProduct[] {
    const route: string[] = [
      `${
        data.isEngagement
          ? API_ROUTE_CBO.GET_ENG_KEY_ASSETS_LIST
          : API_ROUTE_CBO.GET_KEY_ASSETS_LIST
      }/${data.selectedEnagagementId}`,
    ];
    return this.cboService.get({ route: route });
  }

  public getJourneyCapability(
    data: string[],
  ): Promise<IJourneyCapabilitiesResponse> | IJourneyCapabilitiesResponse {
    const route: string[] = [`${API_ROUTE_CBO.GET_JOURNEY_CAPABILITY}`];
    return this.cboService.post({ route }, data);
  }

  public getEngagementConfiguration(
    data: IConfigurePayload,
  ): Promise<IEngagementConfigurationResponse[]> | IEngagementConfigurationResponse[] {
    const route: string[] = [
      `${
        data.isEngagement
          ? API_ROUTE_CBO.GET_ENGAGEMENT_CONFIG.replace(`:engagementId`, data?.engagementId ?? '')
          : API_ROUTE_CBO.GET_PURSUIT_CONFIG.replace(`:engagementId`, data?.engagementId ?? '')
      }`,
    ];
    return this.cboService.get({ route: route });
  }

  public saveEngagementConfiguration(
    data: IEngagementConfigurationPayload,
  ): Promise<IEngagementConfigurationPayload> | IEngagementConfigurationPayload {
    if (data.isEngagement) {
      const route: string[] = [`${API_ROUTE_CBO.SAVE_ENGAGEMENT_CONFIG}`];
      delete data?.isEngagement, delete data?.stage, delete data?.conversionStatus;
      return this.cboService.patch({ route }, data);
    } else {
      const route: string[] = [`${API_ROUTE_CBO.SAVE_PURSUIT_CONFIG}`];
      delete data?.isEngagement;
      return this.cboService.post({ route }, data);
    }
  }

  public deleteEngagement(data: string): Promise<any> | any {
    return this.cboService.delete({ route: [`${API_ROUTE_CBO.DELETE_ENGAGEMENT}/${data}`] });
  }

  public getADAccessToken(data: string): Promise<string> | string {
    const route: string[] = [`${API_ROUTE_CBO.GET_AD_ACCESS_TOKEN}`];
    return this.cboService.post({ route }, { ad_refresh_token: data });
  }
  public postConversionData(
    data: IConversionPayload,
  ): Promise<IStatusUpdateResponse> | IStatusUpdateResponse {
    return this.cboService.post(
      {
        route: [`${API_ROUTE_CBO.ENGAGEMENT_CONVERSION}`],
      },
      data,
    );
  }

  public getKeyAssetsIconList():
    | Promise<IKeyAssetsIconResponseListObject[]>
    | IKeyAssetsIconResponseListObject[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_KEY_ASSETS_ICON_LIST}`];
    return this.cboService.get({ route: route });
  }

  public getDomailList(): Promise<String[]> | String[] {
    const route: string[] = [`${API_ROUTE_CBO.GET_DOMAIN_LIST}`];
    return this.cboService.get({ route });
  }

  /**
   * Validates the pursuit name.
   * CBO-3027_AI_AUTO_ENHANCE_CODE
   *
   * @param data - The payload containing the pursuit name to be validated.
   * @returns A promise that resolves to the validated pursuit name payload.
   */
  public validatePursuitName(
    data: IValidatePursuitPayload,
  ): Promise<IValidatePursuitPayload> | IValidatePursuitPayload {
    const route: string[] = [`${API_ROUTE_CBO.VALIDATE_PURSUIT_NAME}`];
    return this.cboService.post({ route }, data);
  }

  public postEngagementClosureDate(data: IEngagementClosureDatePayload) {
    const route: string[] = [
      `${API_ROUTE_CBO.POST_ENGAGEMENT_CLOSURE_DATE.replace(':projectId', data.engagementId!)}`,
    ];
    delete data.engagementId;
    return this.cboService.post({ route }, data);
  }
}

const engagementService = new EngagementService();
export default engagementService;

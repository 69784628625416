import {
  IAssetDetailPayload,
  IEngagementAssetsObject,
  IEngagementAssetsPayload,
  IMarketplaceAssetModal,
  IMarketplaceAssetPayload,
} from '../../../models/cbo/CboMarketplaceModels';
import { IStoreAction, IStoreFlagAction } from '../../StoreHelper';

export enum CboMarketplaceActionTypes {
  SET_SELECTED_JOURNEY_NAME = 'SET_SELECTED_JOURNEY_NAME',
  SET_SELECTED_BASE_JOURNEY_NAME = 'SET_SELECTED_BASE_JOURNEY_NAME',
  GET_MARKETPLACE_MANDATORY_ASSET_STARTED = 'GET_MARKETPLACE_MANDATORY_ASSET_STARTED',
  GET_MARKETPLACE_MANDATORY_ASSET_SUCCESS = 'GET_MARKETPLACE_MANDATORY_ASSET_SUCCESS',
  GET_MARKETPLACE_MANDATORY_ASSET_ERROR = 'GET_MARKETPLACE_MANDATORY_ASSET_ERROR',
  GET_MARKETPLACE_RECOMMENDED_ASSET_STARTED = 'GET_MARKETPLACE_RECOMMENDED_ASSET_STARTED',
  GET_MARKETPLACE_RECOMMENDED_ASSET_SUCCESS = 'GET_MARKETPLACE_RECOMMENDED_ASSET_SUCCESS',
  GET_MARKETPLACE_RECOMMENDED_ASSET_ERROR = 'GET_MARKETPLACE_RECOMMENDED_ASSET_ERROR',
  ADD_MARKETPLACE_ASSET_STARTED = 'ADD_MARKETPLACE_ASSET_STARTED',
  ADD_MARKETPLACE_ASSET_SUCCESS = 'ADD_MARKETPLACE_ASSET_SUCCESS',
  ADD_MARKETPLACE_ASSET_ERROR = 'ADD_MARKETPLACE_ASSET_ERROR',
  GET_MARKETPLACE_ASSET_DETAILS_STARTED = 'GET_MARKETPLACE_ASSET_DETAILS_STARTED',
  GET_MARKETPLACE_ASSET_DETAILS_SUCCESS = 'GET_MARKETPLACE_ASSET_DETAILS_SUCCESS',
  GET_MARKETPLACE_ASSET_DETAILS_ERROR = 'GET_MARKETPLACE_ASSET_DETAILS_ERROR',
  SET_SELECTED_MARKETPLACE_TAB = 'SET_SELECTED_MARKETPLACE_TAB',
  SET_IS_SMARTSTARTS_CLICKED = 'SET_IS_SMARTSTARTS_CLICKED',
}

export type CboMarketplacePayload =
  | string
  | string[]
  | IEngagementAssetsObject
  | IEngagementAssetsPayload
  | IMarketplaceAssetModal
  | IMarketplaceAssetPayload
  | IAssetDetailPayload
  | boolean
  | Error;

export type CboMarketplaceAction = IStoreFlagAction<
  CboMarketplaceActionTypes,
  CboMarketplacePayload,
  boolean
>;
/* export type CboMarketplaceDocsAction = IStoreAction<CboMarketplaceActionTypes,object>; */
export class CboMarketplaceActions {
  public static setSelectedJourneyName(data: string[]): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.SET_SELECTED_JOURNEY_NAME,
      data,
    };
  }

  public static setSelectedBaseJourneyName(data: string): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.SET_SELECTED_BASE_JOURNEY_NAME,
      data,
    };
  }

  public static getMarketplaceMandatoryAssetsStart(
    data: IMarketplaceAssetPayload,
  ): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_MANDATORY_ASSET_STARTED,
      data,
    };
  }

  public static getMarketplaceMandatoryAssetsSuccess(
    data: IEngagementAssetsObject,
  ): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_MANDATORY_ASSET_SUCCESS,
      data,
    };
  }
  public static getMarketplaceMandatoryAssetsError(error: Error): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_MANDATORY_ASSET_ERROR,
      data: error,
    };
  }

  public static getMarketplaceRecommendedAssetsStart(
    data: IMarketplaceAssetPayload,
  ): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_RECOMMENDED_ASSET_STARTED,
      data,
    };
  }

  public static getMarketplaceRecommendedAssetsSuccess(
    data: IEngagementAssetsObject,
  ): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_RECOMMENDED_ASSET_SUCCESS,
      data,
    };
  }
  public static getMarketplaceRecommendedAssetsError(error: Error): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_RECOMMENDED_ASSET_ERROR,
      data: error,
    };
  }

  //post selected documents against journey
  public static addMarketplaceAssetsStart(data: IEngagementAssetsPayload): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.ADD_MARKETPLACE_ASSET_STARTED,
      data,
    };
  }

  public static addMarketplaceAssetsSuccess(): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.ADD_MARKETPLACE_ASSET_SUCCESS,
    };
  }
  public static addMarketplaceAssetsError(error: Error): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.ADD_MARKETPLACE_ASSET_ERROR,
      data: error,
    };
  }

  //get selected Asset Details for mandatory smartstart assets
  public static getMarketplaceAssetDetailsStart(data: IAssetDetailPayload): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_ASSET_DETAILS_STARTED,
      data,
    };
  }

  public static getMarketplaceAssetDetailsSuccess(
    data: IMarketplaceAssetModal,
  ): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_ASSET_DETAILS_SUCCESS,
      data,
    };
  }
  public static getMarketplaceAssetDetailsError(error: Error): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.GET_MARKETPLACE_ASSET_DETAILS_ERROR,
      data: error,
    };
  }
  public static setSelectedMarketplaceTab(data: string): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.SET_SELECTED_MARKETPLACE_TAB,
      data,
    };
  }
  public static setIsSmartstartsClicked(data: boolean): CboMarketplaceAction {
    return {
      type: CboMarketplaceActionTypes.SET_IS_SMARTSTARTS_CLICKED,
      data,
    };
  }
}

import { CaretDownOutlined, LeftCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout, Typography, Avatar, Popover, Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { NavMenuOptions } from '../../AppConstants';
import { routes } from '../../navigation/Routes';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import './CBOAppHeaderStyles.less';
import AxiosInstance from '../../libs/services/AxiosInstance';
import { IEngagementConfigurationResponse } from '../../libs/models/cbo/EngagementModels';
import { handleZoom } from '../../libs/helper/HelperFunctions';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { useDispatch } from 'react-redux';

const { Header } = Layout;

const CBOAppHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = JSON.parse(storage.getItem(STORAGE_CONSTANTS.userDetails) || '');
  const fullName = `${userDetails?.given_name} ${userDetails?.family_name}` || 'TestUser';
  const instance = AxiosInstance.create();
  const profilePhoto = storage.getItem(STORAGE_CONSTANTS.useProfilePic);
  const [capabilitiesData, setCapabilitiesData] = useState<IEngagementConfigurationResponse>();

  const dispatcher = {
    logout: (data: boolean) => dispatch(SidebarActions.toggleLogout(data)),
  };
  // commented for future implementation
  // const zoomOptions = [
  //   {
  //     label: (
  //       <>
  //         A<sup>-</sup>
  //       </>
  //     ),
  //     value: 'zoom-minus',
  //   },
  //   { label: 'A', value: 'zoom-default' },
  //   {
  //     label: (
  //       <>
  //         A<sup>+</sup>
  //       </>
  //     ),
  //     value: 'zoom-plus',
  //   },
  // ];
  // const [zoomValue, setZoomValue] = useState('zoom-plus');

  const {
    selectedEngagementClientLogo,
    pursuitConfiguration,
    engagementConfiguration,
    isEngagement,
  } = useAppSelector(
    ({
      cboViewSummary: {
        selectedEngagementClientLogo,
        pursuitCapabilitiesSelected,
        engagementCapabilitiesSelected,
        isEngagement,
      },
      engagement: { engagementConfiguration, pursuitConfiguration },
    }: IApplicationState) => ({
      selectedEngagementClientLogo,
      pursuitCapabilitiesSelected,
      engagementCapabilitiesSelected,
      isEngagement,
      engagementConfiguration,
      pursuitConfiguration,
    }),
  );

  useEffect(() => {
    isEngagement
      ? setCapabilitiesData(engagementConfiguration)
      : setCapabilitiesData(pursuitConfiguration);
  }, [engagementConfiguration, pursuitConfiguration]);

  const toggleNavHeader = (key: string) => {
    switch (key) {
      case routes.cboDashboard:
        return (
          <Typography className="cbo-header-title"> {NavMenuOptions.ENG_DASHBOARD}</Typography>
        );
      case routes.cboEngagementDashboard:
        return (
          <Typography className="cbo-header-title">
            {NavMenuOptions.CBO_ENGAGEMENT_DASHBOARD}
          </Typography>
        );
      case routes.cboEngagementSummary:
        return (
          <div className="cbo-summary-title">
            <Button
              type="link"
              className="cbo-header-back-btn"
              onClick={() =>
                history.push(isEngagement ? routes.cboEngagementDashboard : routes.cboDashboard)
              }
            >
              <LeftCircleOutlined style={{ fontSize: '1rem' }} />
              Back to Dashboard
            </Button>
            <p className="cbo-summary-title-bread">
              <div className="client-logo-container">
                {selectedEngagementClientLogo ? (
                  <>
                    <img className="client-logo" src={selectedEngagementClientLogo} alt="" />
                  </>
                ) : (
                  <span className="client-name-initial">
                    {capabilitiesData?.clientName?.slice(0, 2)?.toUpperCase()}
                  </span>
                )}
                {isEngagement
                  ? NavMenuOptions.CBO_ENGAGEMENT_SUMMARY
                  : NavMenuOptions.CBO_PURSUIT_SUMMARY}
                {capabilitiesData && (
                  <span className="cbo-header-engagement-name">
                    / {capabilitiesData.engagementName}
                  </span>
                )}
              </div>
            </p>
          </div>
        );
      case routes.cboMarketplace:
        return (
          <div className="cbo-summary-title">
            <Button
              type="link"
              className="cbo-header-back-btn"
              onClick={() => history.push(routes.cboDashboard)}
            >
              <LeftCircleOutlined style={{ fontSize: '1rem' }} />
              Back
            </Button>
            <p className="cbo-summary-title-bread cbo-marketplace">
              {NavMenuOptions.CBO_MARKETPLACE}
            </p>
          </div>
        );
      case routes.cboReports:
        return <Typography className="cbo-header-title"> {NavMenuOptions.CBO_REPORTS}</Typography>;
      case routes.clientEngagementManagement:
        return (
          <Typography className="cbo-header-title">
            {NavMenuOptions.CBO_CLIENT_MANAGEMENT}
          </Typography>
        );
      case routes.cboUserManagement:
        return (
          <Typography className="cbo-header-title">{NavMenuOptions.CBO_USER_MANAGEMENT}</Typography>
        );
      // add one more case for cboUserClientMapping:
      case routes.cboUserClientMapping:
        return (
          <Typography className="cbo-header-title">{NavMenuOptions.USER_CLIENT_MAPPING}</Typography>
        );
      case routes.cboFeedback:
        return (
          <Typography className="cbo-header-title"> {NavMenuOptions.CBO_USER_FEEDBACK}</Typography>
        );
      case routes.cboCapabilityManagement:
        return (
          <Typography className="cbo-header-title">
            {NavMenuOptions.CBO_CAPABILITY_MANAGEMENT}
          </Typography>
        );
      case routes.cboJourneyBuilder:
        return (
          <Typography className="cbo-header-title">{NavMenuOptions.CBO_JOURNEY_BUILDER}</Typography>
        );
      case routes.cboMarketplaceSearch:
        return (
          <Typography className="cbo-header-title">
            {NavMenuOptions.CBO_MARKETPLACE_SEARCH}
          </Typography>
        );

      case routes.cboSmartStart:
        return (
          <Typography className="cbo-header-title">{NavMenuOptions.CBO_SMART_START}</Typography>
        );
      default:
        return <Typography className="cbo-header-title"></Typography>;
    }
  };

  const logoutHandler = () => {
    dispatcher.logout(false);
    storage.clearAll();
    history.push(routes.cbologin);
  };
  // commented for future implementation
  // const onZoomChange = ({ target: { value } }: RadioChangeEvent) => {
  //   setZoomValue(value);
  //   const existingBodyZoomCLass = document.body?.className
  //     ?.split(' ')
  //     ?.filter((cl) => cl.includes('zoom'));
  //   existingBodyZoomCLass?.map((zoomClass) => {
  //     document.body?.classList?.remove(zoomClass);
  //   });
  //   document.body.classList.add(value);
  //   storage.setItem(STORAGE_CONSTANTS.zoomValue, value);
  //   handleZoom('cbo-sidebar-container');
  // };

  return (
    <Header className="cbo-header-container">
      <div className="cbo-header">
        {toggleNavHeader(location.pathname)}
        <div className="cbo-header-right-side">
          {/* commented for future implementation */}
          {/* <div className='zoom-changer-container'>
          <Radio.Group className='zoom-changer' options={zoomOptions} onChange={onZoomChange} value={zoomValue} optionType="button" />
          <Popover content='Please adjust your zoom resolution for better user experience.' placement="bottomRight" >
           <InfoCircleOutlined />
           </Popover>
          </div> */}

          <img
            className="cbo-header-notification tour-notification"
            src={`${process.env.PUBLIC_URL}/config-icons/cbo/white_theme_bell_icon.svg`}
            alt="bell icon"
          />
          <div className="cbo-header-profile tour-profile">
            {profilePhoto && btoa(atob(profilePhoto)) == profilePhoto ? (
              <div className="cbo-header-profile-pic">
                <img src={`data:image/jpeg;base64,${profilePhoto}`} alt="user-profile-pic" />
              </div>
            ) : (
              <Avatar size="large" icon={<UserOutlined />} />
            )}
            <div className="cbo-header-profile-details">
              <Typography className="cbo-profile-name">{fullName}</Typography>
              <Typography className="cbo-profile-title">Pursuit Lead</Typography>
            </div>
            <Popover
              trigger="click"
              overlayClassName="cbo-logout"
              placement="bottomRight"
              content={
                <Button type="link" className="cbo-header-profile-popover" onClick={logoutHandler}>
                  Logout
                </Button>
              }
            >
              <CaretDownOutlined className="cbo-header-profile-arrow-down" />
            </Popover>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default CBOAppHeader;

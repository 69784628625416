import { IMarketplaceAssetPayload } from '../../../models/cbo/CboMarketplaceModels';
import {
  IADAccessTokenResponse,
  IConversionPayload,
  IConfigurePayload,
  IEngagementConfigurationPayload,
  IEngagementConfigurationResponse,
  IEngagementConfigurationSaveResponse,
  IJourneyCapabilitiesData,
  IKeyAssetsIconResponseListObject,
  IPredefinedJourneyResponse,
  IProduct,
  IValidatePursuitPayload,
  IValidatePursuitResponse,
  IJourneyCapabilitiesResponse,
  IEngagementClosureDatePayload,
  IStatusUpdateResponse,
} from '../../../models/cbo/EngagementModels';

import { IStoreAction, IStoreFlagAction } from '../../StoreHelper';

export enum EngagementActionTypes {
  GET_CE_PREDEFINED_JOURNEY_LIST_STARTED = 'GET_CE_PREDEFINED_JOURNEY_LIST_STARTED',
  GET_CE_PREDEFINED_JOURNEY_LIST_SUCCESS = 'GET_CE_PREDEFINED_JOURNEY_LIST_SUCCESS',
  GET_CE_PREDEFINED_JOURNEY_LIST_ERROR = 'GET_CE_PREDEFINED_JOURNEY_LIST_ERROR',
  GET_JOURNEYCAPABILITY_DATA_STARTED = 'GET_JOURNEYCAPABILITY_DATA_STARTED',
  GET_JOURNEYCAPABILITY_DATA_SUCCESS = 'GET_JOURNEYCAPABILITY_DATA_SUCCESS',
  GET_JOURNEYCAPABILITY_DATA_ERROR = 'GET_JOURNEYCAPABILITY_DATA_ERROR',
  GET_ENGAGEMENTCONFIG_DATA_STARTED = 'GET_ENGAGEMENTCONFIG_DATA_STARTED',
  GET_ENGAGEMENTCONFIG_DATA_SUCCESS = 'GET_ENGAGEMENTCONFIG_DATA_SUCCESS',
  GET_ENGAGEMENTCONFIG_DATA_ERROR = 'GET_ENGAGEMENTCONFIG_DATA_ERROR',
  SAVE_ENGAGEMENTCONFIG_DATA_STARTED = 'SAVE_ENGAGEMENTCONFIG_DATA_STARTED',
  SAVE_ENGAGEMENTCONFIG_DATA_SUCCESS = 'SAVE_ENGAGEMENTCONFIG_DATA_SUCCESS',
  SAVE_ENGAGEMENTCONFIG_DATA_ERROR = 'SAVE_ENGAGEMENTCONFIG_DATA_ERROR',
  RESET_SELECTED_ENGAGEMENT = 'RESET_SELECTED_ENGAGEMENT',
  GET_AD_ACCESS_TOKEN_STARTED = 'GET_AD_ACCESS_TOKEN_STARTED',
  GET_AD_ACCESS_TOKEN_SUCCESS = 'GET_AD_ACCESS_TOKEN_SUCCESS',
  GET_AD_ACCESS_TOKEN_ERROR = 'GET_AD_ACCESS_TOKEN_ERROR',
  GET_KEY_ASSETS_ICONS_LIST_STARTED = 'GET_KEY_ASSETS_ICONS_LIST_STARTED',
  GET_KEY_ASSETS_ICONS_LIST_SUCCESS = 'GET_KEY_ASSETS_ICONS_LIST_SUCCESS',
  GET_KEY_ASSETS_ICONS_LIST_ERROR = 'GET_KEY_ASSETS_ICONS_LIST_ERROR',
  GET_KEY_ASSETS_LIST_STARTED = 'GET_KEY_ASSETS_LIST_STARTED',
  GET_KEY_ASSETS_LIST_SUCCESS = 'GET_KEY_ASSETS_LIST_SUCCESS',
  GET_KEY_ASSETS_LIST_ERROR = 'GET_KEY_ASSETS_LIST_ERROR',
  GET_CONVERSION_DATA_STARTED = 'GET_CONVERSION_DATA_STARTED',
  GET_CONVERSION_DATA_SUCCESS = 'GET_CONVERSION_DATA_SUCCESS',
  GET_CONVERSION_DATA_ERROR = 'GET_CONVERSION_DATA_ERROR',
  GET_DOMAIN_DATA_SUCCESS = 'GET_DOMAIN_DATA_SUCCESS',
  GET_DOMAIN_DATA_ERROR = 'GET_DOMAIN_DATA_ERROR',
  GET_DOMAIN_DATA_STARTED = 'GET_DOMAIN_DATA_STARTED',
  RESST_SUMMARY_DATA = 'RESST_SUMMARY_DATA,',
  VALIDATE_PURSUIT_NAME_STARTED = 'VALIDATE_PURSUIT_NAME_STARTED',
  VALIDATE_PURSUIT_NAME_SUCCESS = 'VALIDATE_PURSUIT_NAME_SUCCESS',
  VALIDATE_PURSUIT_NAME_ERROR = 'VALIDATE_PURSUIT_NAME_ERROR',
  POST_ENG_CLOSURE_DATE_STARTED = 'POST_ENG_CLOSURE_DATE_STARTED',
  POST_ENG_CLOSURE_DATE_SUCCESS = 'POST_ENG_CLOSURE_DATE_SUCCESS',
  POST_ENG_CLOSURE_DATE_ERROR = 'POST_ENG_CLOSURE_DATE_ERROR',
  RESET_PURSUIT_STATUS_RESPONSE = 'RESET_PURSUIT_STATUS_RESPONSE',
  RESET_ENGAGEMENT_STATUS_RESPONSE = 'RESET_ENGAGEMENT_STATUS_RESPONSE',
}

export type EngagementActionPayload =
  | Error
  | null
  | string
  | string[]
  | IEngagementConfigurationPayload
  | IPredefinedJourneyResponse[]
  | IJourneyCapabilitiesData[]
  | IJourneyCapabilitiesResponse
  | IEngagementConfigurationResponse
  | IEngagementConfigurationSaveResponse
  | IADAccessTokenResponse
  | IProduct[]
  | IKeyAssetsIconResponseListObject[]
  | IConversionPayload
  | IConfigurePayload
  | IMarketplaceAssetPayload
  | IValidatePursuitPayload
  | IValidatePursuitResponse
  | String[]
  | IEngagementClosureDatePayload
  | IStatusUpdateResponse;

export type EngagementAction = IStoreFlagAction<
  EngagementActionTypes,
  EngagementActionPayload,
  boolean
>;
/**
 * Represents a collection of static methods that generate engagement actions.
 */
export class EngagementActions {
  public static getPredefinedJourneyListStart(): EngagementAction {
    return {
      type: EngagementActionTypes.GET_CE_PREDEFINED_JOURNEY_LIST_STARTED,
    };
  }
  public static getPredefinedJourneyListSuccess(
    data: IPredefinedJourneyResponse[],
  ): EngagementAction {
    return {
      type: EngagementActionTypes.GET_CE_PREDEFINED_JOURNEY_LIST_SUCCESS,
      data,
    };
  }
  public static getPredefinedJourneyListError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.GET_CE_PREDEFINED_JOURNEY_LIST_ERROR,
      data: error,
    };
  }
  public static getjourneyCapabilityDataStart(data: string[]): EngagementAction {
    return {
      type: EngagementActionTypes.GET_JOURNEYCAPABILITY_DATA_STARTED,
      data,
    };
  }
  public static getDomainListDataStart(): EngagementAction {
    return {
      type: EngagementActionTypes.GET_DOMAIN_DATA_STARTED,
    };
  }

  public static getDomainListDataSuccess(data: String[]): EngagementAction {
    return {
      type: EngagementActionTypes.GET_DOMAIN_DATA_SUCCESS,
      data,
    };
  }
  public static getDomainListDataError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.GET_DOMAIN_DATA_ERROR,
      data: error,
    };
  }

  public static getjourneyCapabilityDataSuccess(
    data: IJourneyCapabilitiesResponse,
  ): EngagementAction {
    return {
      type: EngagementActionTypes.GET_JOURNEYCAPABILITY_DATA_SUCCESS,
      data,
    };
  }
  public static getjourneyCapabilityDataError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.GET_JOURNEYCAPABILITY_DATA_ERROR,
      data: error,
    };
  }

  public static getEngagementConfigDataStart(data: IConfigurePayload): EngagementAction {
    return {
      type: EngagementActionTypes.GET_ENGAGEMENTCONFIG_DATA_STARTED,
      data,
    };
  }
  public static getEngagementConfigDataSuccess(
    data: IEngagementConfigurationResponse,
    isEngagement: boolean,
  ): EngagementAction {
    return {
      type: EngagementActionTypes.GET_ENGAGEMENTCONFIG_DATA_SUCCESS,
      data,
      flag: isEngagement,
    };
  }
  public static getEngagementConfigDataError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.GET_ENGAGEMENTCONFIG_DATA_ERROR,
      data: error,
    };
  }

  public static saveEngagementConfigDataStart(
    data: IEngagementConfigurationPayload,
  ): EngagementAction {
    return {
      type: EngagementActionTypes.SAVE_ENGAGEMENTCONFIG_DATA_STARTED,
      data,
    };
  }
  public static saveEngagementConfigDataSuccess(
    data: IEngagementConfigurationSaveResponse,
  ): EngagementAction {
    return {
      type: EngagementActionTypes.SAVE_ENGAGEMENTCONFIG_DATA_SUCCESS,
      data,
    };
  }
  public static saveEngagementConfigDataError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.SAVE_ENGAGEMENTCONFIG_DATA_ERROR,
      data: error,
    };
  }

  public static resetSelectedEngagement(): EngagementAction {
    return {
      type: EngagementActionTypes.RESET_SELECTED_ENGAGEMENT,
    };
  }

  public static getADAccessTokenStart(data: string): EngagementAction {
    return {
      type: EngagementActionTypes.GET_AD_ACCESS_TOKEN_STARTED,
      data,
    };
  }
  public static getADAccessTokenSuccess(data: IADAccessTokenResponse): EngagementAction {
    return {
      type: EngagementActionTypes.GET_AD_ACCESS_TOKEN_SUCCESS,
      data,
    };
  }
  public static getADAccessTokenError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.GET_AD_ACCESS_TOKEN_ERROR,
      data: error,
    };
  }
  public static getKeyAssetsIconListStart(): EngagementAction {
    return {
      type: EngagementActionTypes.GET_KEY_ASSETS_ICONS_LIST_STARTED,
    };
  }
  public static getKeyAssetsIconListSuccess(
    data: IKeyAssetsIconResponseListObject[],
  ): EngagementAction {
    return {
      type: EngagementActionTypes.GET_KEY_ASSETS_ICONS_LIST_SUCCESS,
      data,
    };
  }
  public static getKeyAssetsIconListError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.GET_KEY_ASSETS_ICONS_LIST_ERROR,
      data: error,
    };
  }

  public static getKeyAssetsListStart(data: IMarketplaceAssetPayload): EngagementAction {
    return {
      type: EngagementActionTypes.GET_KEY_ASSETS_LIST_STARTED,
      data,
    };
  }
  public static getKeyAssetsListSuccess(data: IProduct[]): EngagementAction {
    return {
      type: EngagementActionTypes.GET_KEY_ASSETS_LIST_SUCCESS,
      data,
    };
  }
  public static getKeyAssetsListError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.GET_KEY_ASSETS_ICONS_LIST_ERROR,
      data: error,
    };
  }

  //conversion
  public static getConversionDataStart(data: IConversionPayload): EngagementAction {
    return {
      type: EngagementActionTypes.GET_CONVERSION_DATA_STARTED,
      data,
    };
  }
  public static getConversionDataSuccess(data: IStatusUpdateResponse): EngagementAction {
    return {
      type: EngagementActionTypes.GET_CONVERSION_DATA_SUCCESS,
      data,
    };
  }
  public static getConversionDataError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.GET_CONVERSION_DATA_ERROR,
      data: error,
    };
  }

  public static resetSummaryData(): EngagementAction {
    return {
      type: EngagementActionTypes.RESST_SUMMARY_DATA,
    };
  }

  //Start: CBO-3027_AI_AUTO_ENHANCE_CODE

  public static validatePursuitNameStart(data: IValidatePursuitPayload): EngagementAction {
    return {
      type: EngagementActionTypes.VALIDATE_PURSUIT_NAME_STARTED,
      data,
    };
  }
  public static validatePursuitNameSuccess(data: IValidatePursuitResponse): EngagementAction {
    return {
      type: EngagementActionTypes.VALIDATE_PURSUIT_NAME_SUCCESS,
      data,
    };
  }
  public static validatePursuitNameError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.VALIDATE_PURSUIT_NAME_ERROR,
      data: error,
    };
  }

  public static postEngagementClosureDateStarted(
    data: IEngagementClosureDatePayload,
  ): EngagementAction {
    return {
      type: EngagementActionTypes.POST_ENG_CLOSURE_DATE_STARTED,
      data,
    };
  }
  public static postEngagementClosureDateSuccess(data: IStatusUpdateResponse): EngagementAction {
    return {
      type: EngagementActionTypes.POST_ENG_CLOSURE_DATE_SUCCESS,
      data,
    };
  }
  public static postEngagementClosureDateError(error: Error): EngagementAction {
    return {
      type: EngagementActionTypes.POST_ENG_CLOSURE_DATE_ERROR,
      data: error,
    };
  }
  public static resetPursuitStatusResponse(): EngagementAction {
    return {
      type: EngagementActionTypes.RESET_PURSUIT_STATUS_RESPONSE,
    };
  }
  public static resetEngagementStatusResponse(): EngagementAction {
    return {
      type: EngagementActionTypes.RESET_ENGAGEMENT_STATUS_RESPONSE,
    };
  }
}
